import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const NexogenPageHeroImage = props => {
  const data = useStaticQuery(graphql`
    query {
      nexogenPageHeroImage: file(
        relativePath: { eq: "nexogen-page-hero.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Img fluid={data.nexogenPageHeroImage.childImageSharp.fluid} {...props} />
  );
};

export default NexogenPageHeroImage;
