import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage } from 'react-intl';
import cn from 'classnames';
import App from '../../App';
import Hero from '../../components/Hero';
import ContactSection from '../../components/ContactSection';
import TechInfo from '../../components/TechInfo';
import MiniQuotationSection from '../../components/QuotationSection/MiniQuotationSection';
import Container from '../../components/Container';
import BlueDotsIcon from '../../assets/icons/BlueDotsIcon';
import NexogenPageHeroImage from '../../gatsbyImages/NexogenPageHeroImage';
import NexogenMockupImage from '../../gatsbyImages/NexogenMockupImage';
import styles from './projectPages.module.scss';

const messages = defineMessages({
  title: {
    id: 'nexogen.title',
    defaultMessage: 'Nexogen'
  },
  description: {
    id: 'nexogen.description',
    defaultMessage:
      'Location: Europe {br} Project Length: 9 months, ongoing {br}'
  },
  contactSectionTitle: {
    id: 'nexogen.contactSectionTitle',
    defaultMessage: 'How can we help you?'
  },
  contactSectionDescription: {
    id: 'nexogen.contactSectionDescription',
    defaultMessage: `Tell us the 1,000 foot view of your project and we'll put our brains, tools and {br} processes in place to make it a reality.`
  },
  techUsed: {
    id: 'nexogen.techUsed',
    defaultMessage: 'Tech used: '
  },
  react: {
    id: 'nexogen.react',
    defaultMessage: 'React'
  },
  firstSectionTitle: {
    id: 'nexogen.firstSectionTitle',
    defaultMessage:
      'How StreamBright created a customer dashboard for Nexogen’s Full Truckload Logistics solution'
  },
  firstSectionDescription1: {
    id: 'nexogen.firstSectionDescription1',
    defaultMessage:
      'Nexogen helps enterprises adopting and integrating automated decision pipelines in order to make faster and better decisions so they can focus on their core Businesses. Focusing on the logistics industry they deliver mathematical optimization with custom software solutions creating millions of dollars of value.'
  },
  firstSectionDescription2: {
    id: 'nexogen.firstSectionDescription2',
    defaultMessage:
      'Deeply integrating their AI based engine to bluechip Full-Truck Logistics companies’ systems and processes, they are able to reduce costs drastically. However, further growing their company and widening their customer base they needed their own UI to offer this tremendous value to players all over Europe.'
  },
  firstSectionDescription3: {
    id: 'nexogen.firstSectionDescription3',
    defaultMessage:
      'Streambright enabled Nexogen to develop just what they really needed in order to switch gears without the distraction of building a capacity in-house that is not their core business - front-end development.'
  },
  miniQuotationDescription1: {
    id: 'nexogen.miniQuotationDescription1',
    defaultMessage:
      'The goal was to find an on-demand development partner who was experienced enough in the field to integrate with our complex backend, and scrupulous enough to deliver front end tasks that complied with our code quality standards.'
  },
  secondSectionTitle: {
    id: 'nexogen.secondSectionTitle',
    defaultMessage: 'Nexogen x StreamBright'
  },
  secondSectionDescription: {
    id: 'nexogen.secondSectionDescription',
    defaultMessage:
      'After a few frustrating outsourcing experiences where their partners couldn’t catch up with their release management flows and existing structure, COO LeHoang Giang and CTO Mark Farkas found a match in StreamBright.'
  },
  miniQuotationDescription2: {
    id: 'nexogen.miniQuotationDescription2',
    defaultMessage:
      'With Adam and the StreamBright team, the difference in chemistry and quality was noticeable from the first engagement. They were proactive in onboarding themselves to our tech stack and quickly became an instrumental part of our sprints without additional oversight required from our side.'
  },
  thirdSectionTitle: {
    id: 'nexogen.thirdSectionTitle',
    defaultMessage: 'The Results'
  },
  thirdSectionDescription: {
    id: 'nexogen.thirdSectionDescription',
    defaultMessage:
      'Within 6 months, we released Nexogen’s customer dashboard directly integrated into its product and ready to release to their customers. Moving forward the feature development do not stop and Streambright’s team working with Nexogen will grow further.'
  },
  miniQuotationDescription3: {
    id: 'nexogen.miniQuotationDescription3',
    defaultMessage:
      'Our cooperation with the Streambright team is really smooth - we agree on scope and the team gets it done. We especially value the pro-activity from the developer level: we can count on the honesty and expertise of all the team members from junior to senior level. They raise issues about the scope and recommend solutions to consider. We have the piece of mind that the team only executes on tasks that really thought through and make sense.'
  }
});

function Nexogen() {
  return (
    <App>
      <Helmet>
        <title>Streambright - Case Study: Nexogen</title>
        <meta
          name="description"
          content="How StreamBright created a customer dashboard for Nexogen’s Full Truckload Logistics solution."
        />
      </Helmet>
      <Hero
        icon={<BlueDotsIcon />}
        title={<FormattedMessage {...messages.title} />}
        description={
          <>
            <FormattedMessage
              {...messages.description}
              values={{ br: <br /> }}
            />
            <div className={styles.textContainer}>
              <FormattedMessage {...messages.techUsed} />
              <TechInfo title={messages.react} />
            </div>
          </>
        }
        backgroundImage={NexogenPageHeroImage}
        image={NexogenMockupImage}
      />
      <Container>
        <div className={styles.title}>
          <FormattedMessage {...messages.firstSectionTitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.firstSectionDescription1} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.firstSectionDescription2} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.firstSectionDescription3} />
        </div>
        <MiniQuotationSection
          description={messages.miniQuotationDescription1}
        />
        <div className={styles.title}>
          <FormattedMessage {...messages.secondSectionTitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.secondSectionDescription} />
        </div>
        <div className={cn(styles.imageContainer, styles.mockupImageContainer)}>
          <NexogenMockupImage
            imgStyle={{ objectFit: 'contain' }}
            critical
            fadeIn={false}
          />
          <div className={styles.topGreenIcon}>
            <BlueDotsIcon />
          </div>
        </div>
        <MiniQuotationSection
          description={messages.miniQuotationDescription2}
        />
        <div className={styles.title}>
          <FormattedMessage {...messages.thirdSectionTitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.thirdSectionDescription} />
        </div>
        <MiniQuotationSection
          description={messages.miniQuotationDescription3}
        />
      </Container>
      <div id="Contact">
        <ContactSection
          title={messages.contactSectionTitle}
          description={
            <FormattedMessage
              {...messages.contactSectionDescription}
              values={{ br: <br /> }}
            />
          }
        />
      </div>
    </App>
  );
}

export default Nexogen;
